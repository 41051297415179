body {
    font-family: 'Montserrat' !important;
}

.hide {
    opacity: 0 !important;
}

.clickable, .btn {
    cursor: pointer !important;
}

.default-cursor {
    cursor: default !important;
}

.main-page.dark {
    background-color: #161616 !important;
}

.main-page.light {
    background-color: #F4F7FC !important;
}

.modal-content-dark-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #F4F7FC;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.navbar.light {
    background-color: #FFFFFF;
}

.navbar.dark {
    background-color: #2C2C2C;
}

.navbar button {
    color: #92929D !important;
}

.side-nav-body {
    position: fixed !important;
}

    .side-nav-body.light {
        box-shadow: 0px 0px 10px #92929D;
    }

.top-nav-body {
    position: fixed !important;
    top: 0px;
    width: 100%;
    z-index: 2;
    max-height: 91px;
}

    .top-nav-body.light {
        z-index: 2;
        box-shadow: 0px -5px 10px #92929D;
    }

.top-nav-container-spacer {
    min-height: 91px;
    max-height: 91px;
    width: 100%;
}

.side-nav {
    flex-direction: column !important;
    align-items: flex-start !important;
    height: calc(100vh - 91px);
    max-width: 254px !important;
    min-width: 254px !important;
    position: fixed;
    top: 91px;
    z-index: 1;
    overflow-y: auto;
    padding-right: 4px;
    overflow-x: hidden;
}

    .side-nav.closed {
        max-width: 60px !important;
        min-width: 60px !important;
    }

    .side-nav::-webkit-scrollbar {
        width: 4px;
    }

    .side-nav::-webkit-scrollbar-track.rs-theme-dark {
        background-color: #2C2C2C;
    }

    .side-nav::-webkit-scrollbar-track.rs-theme-light {
        background-color: #E1EFFC;
    }

    .side-nav::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    .side-nav::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .side-nav::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.side-nav-container-spacer {
    height: calc(100vh - 91px);
    min-height: 660px;
    max-width: 250px !important;
    min-width: 250px !important;
    position: static !important;
}

    .side-nav-container-spacer.closed {
        max-width: 60px !important;
        min-width: 60px !important;
    }

.side-nav-item {
    height: 60px !important;
    width: 254px !important;
    background-color: transparent !important;
}

    .side-nav-item:hover {
        background-color: rgba(0,121,221,0.05);
    }

    .side-nav-item.closed {
        width: 60px !important;
        background-color: transparent !important;
    }

.side-nav-link {
    width: inherit;
    font-size: 14px;
    height: 60px;
    display: inline-block;
    padding-left: 20px !important;
}

span.nav-link {
    color: #92929D;
}

    span.nav-link:hover {
        color: #0079DD;
    }

.support-link {
    display: block;
    color: inherit !important;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.rel-link {
    padding-left: 0px !important;
}

.side-nav-dropdown {
    cursor: pointer;
}

.side-nav-dropend {
    float: right;
    line-height: 47px;
}

.nav-icon, .theme-icon {
    display: inline-block;
    width: 18px;
    line-height: 47px;
    font-size: 14px !important;
    font-weight: 700 !important;
}

    .theme-icon.light {
        color: #0079DD;
    }

.rel-emblem-a {
    position: relative;
    left: 22px;
    top: -5px;
}

.nav-text, .theme-text {
    margin-left: 1rem;
    line-height: 47px;
    vertical-align: middle;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.side-nav-external-link {
    line-height: 47px;
    vertical-align: middle;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.navbar button:hover {
    color: #0079DD !important;
}

.page-content {
    max-width: calc(100vw - 250px) !important;
}

    .page-content.closed {
        max-width: calc(100vw - 60px) !important;
    }

    .page-content.dark {
        background-color: #161616;
    }

    .page-content.light {
        background-color: #F4F7FC;
    }

.main-page {
    max-width: 100vw !important;
}

.disabledLink, .disabledLink:hover {
    opacity: 0.5;
    cursor: default !important;
    pointer-events: none;
}

a {
    color: #92929D !important;
    text-decoration: none !important;
}

    a:hover {
        color: #0079DD !important;
        text-decoration: none !important;
    }

    a.side-nav-link.nav-link.dark {
        color: #92929D !important;
    }

    a.side-nav-link.nav-link.light {
        color: #171725 !important;
    }

    .navbar a.active, .navbar a.active:hover, a.active, a.active:hover, a.active:hover .nav-icon, a.active:hover .nav-text {
        color: #0079DD !important;
    }

    a.column-filter-icon {
        padding: 0.5rem;
        margin-left: 0.25rem;
        border: 1px solid #92929D;
        border-radius: 20px;
        font-size: 0.75rem;
    }

        a.column-filter-icon:hover {
            border: 1px solid #0079DD;
        }

    a.dark.column-filter-icon {
        color: #F4F7FC !important;
    }

    a.light.column-filter-icon {
        color: #131523 !important;
    }


.side-nav .active {
    background-color: rgba(0,121,221,0.05);
    border-left-color: #0079DD;
    border-left-width: 3px;
    border-left-style: solid;
    padding-left: 17px !important;
}

.top-nav {
    padding: 0 !important;
}

    .top-nav .active {
        border-bottom-color: #0079DD;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

.adminNavItem {
    z-index: 1;
}

#navPartner {
    font-weight: 700;
    color: #92929D;
}

.oasis-rounded {
    border-radius: 0.75rem !important;
}

.widget.dark, .widget-group-no-data.dark {
    background-color: #2C2C2C !important;
    color: #F4F7FC;
}

.widget.light, .widget-group-no-data.light {
    background-color: #FFFFFF !important;
    color: #131523;
    box-shadow: 4px 4px 10px #92929D;
}

.widgetChart {
    padding-top: 2rem;
}

.graph-tooltip {
    padding: 10px 10px 0 10px;
    border-radius: 5px;
}

    .graph-tooltip.light {
        border: 1px solid #131523;
        color: #131523;
        background: #F4F7FC;
    }

    .graph-tooltip.dark {
        border: 1px solid #F4F7FC;
        color: #F4F7FC;
        background: #161616;
    }

.dark .react-resizable-handle {
    border-right: 1px solid #92929D;
    border-bottom: 1px solid #92929D;
}

.light .react-resizable-handle {
    border-right: 1px solid #92929D;
    border-bottom: 1px solid #92929D;
}

.stat-card {
    background: rgb(44,44,44);
}

    .stat-card.dark {
        background: #2C2C2C;
        color: #F4F7FC;
    }

    .stat-card.light {
        background: #FFFFFF;
        color: #131523;
        box-shadow: 4px 4px 10px #92929D;
    }

.page-card {
    background: rgb(44,44,44);
    flex: 0 0 90vw;
    height: 100%;
    min-height: 612px;
    max-height: calc(100vh - 140px);
}

    .page-card.dark {
        background: #2C2C2C;
        color: #F4F7FC;
    }

    .page-card.light {
        background: #FFFFFF;
        color: #131523;
    }

.page-card-header {
    height: 10%;
}

.page-card-body {
    height: 90%;
}

.info-card {
    background: rgb(44,44,44);
}

    .info-card.dark {
        background: #2C2C2C;
        color: #F4F7FC;
    }

    .info-card.light {
        background: #FFFFFF;
        color: #131523;
        box-shadow: 4px 4px 10px #92929D;
    }

.oasisCheckbox {
    display: block;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    margin-left: 0.25em;
    margin-right: auto;
    vertical-align: top;
    background-color: #F4F7FC;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.25em;
    appearance: none;
    -webkit-print-color-adjust: exact;
}

    .oasisCheckbox:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-color: #0079DD;
        border-color: #0d6efd;
    }

#globalLinkCheckbox.oasisCheckbox {
    width: 1.5em;
    height: 1.5em;
    margin: auto;
    margin-top: 0.4em;
}

.allEntitiesCheckbox {
    float: left;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    margin-left: -1.5em;
    vertical-align: top;
    background-color: #F4F7FC;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.25em;
    appearance: none;
    -webkit-print-color-adjust: exact;
}

    .allEntitiesCheckbox:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-color: #0079DD;
        border-color: #0d6efd;
    }

.admin-page {
    width: 100%;
}

#admin-container {
    height: 900%;
    margin-left: 0;
    margin-right: 0;
}

.administration-search-container {
    min-height: 11.11%;
    position: relative;
    top: -11.11%;
}

.administration-list {
    min-height: 100%;
    position: relative;
    top: -11.11%;
}

.maintenance-page-content {
    margin-left: 7rem;
    margin-right: 7rem;
}

#maintenanceModeToggle, label, #themeToggle {
    cursor: pointer !important;
}

#maintenanceModeToggle {
    cursor: pointer !important;
    box-shadow: none;
    background-color: #92929D;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

    #maintenanceModeToggle:checked {
        background-color: #0079DD;
    }

#themeToggle {
    padding-left: 20px;
    height: 60px;
    width: 250px;
    color: #92929D;
}

    #themeToggle.closed {
        width: 60px;
    }

.searchIcon {
    position: relative;
    left: 26px;
    top: 1px;
}

.kb-date {
    flex: 0 0 115px;
}

h2, .nav-link {
    font-weight: 700 !important;
}

.dropdown, .dropdown-item, a {
    cursor: pointer !important;
}

.sidenav-popup {
    background-color: #FFFFFF !important;
    border-color: #92929D !important;
    border: 2px solid rgba(0,0,0,.15) !important;
    border-radius: 5px;
    position: fixed;
    z-index: 3;
    padding: 5px 0px 5px 0px;
}

    .sidenav-popup.dark {
        background-color: #2C2C2C !important;
    }

.dropdown-menu.dark {
    background-color: #2C2C2C !important;
    border-color: #92929D !important;
    border: 2px solid rgba(0,0,0,.15) !important;
}

.dropdown-menu.light {
    background-color: #FFFFFF !important;
    border-color: #92929D !important;
    border: 2px solid rgba(0,0,0,.15) !important;
}

#table-header-dropdown-menu.dark {
    background-color: #161616 !important;
}

#table-header-dropdown-menu.light {
    background-color: #FFFFFF !important;
}

.dropdown-item {
    padding: 5px 10px 5px 10px !important;
}

    .dropdown-item.dark:hover {
        background-color: #2C2C2C !important;
        color: #0079DD !important;
    }

    .dropdown-item.light:hover {
        background-color: #F2F8FE !important;
        color: #0079DD !important;
    }

    .dropdown-item.light {
        color: #161616 !important;
    }

    .dropdown-item.dark {
        background-color: #2C2C2C !important;
        color: #92929d !important;
    }

.search-input, .column-search-input {
    border: 1px solid;
    border-color: #92929D;
    border-radius: 20px !important;
    background-color: transparent;
}

.search-input, .column-search-input, .column-search-spacer {
    padding-right: 10px;
}

    .search-input:focus, .column-search-input:focus {
        outline: none !important;
    }

    .search-input.dark, .column-search-input.dark {
        color: #F4F7FC;
    }

    .search-input.light, .column-search-input.light {
        color: #161616;
    }

.search-input {
    height: 30.4px;
    width: 200px;
    padding-left: 33px;
}

.column-search-input, .column-search-spacer {
    height: 28px;
    width: 100%;
    padding-left: 11px;
}

.search-btn {
    cursor: pointer !important;
    background-color: transparent;
    border: none;
    padding: 0px;
    color: #92929D;
}

    .search-btn:hover {
        color: #0079DD;
    }

.btn-block {
    width: 100%;
}

.btn-new {
    background-color: #0079DD !important;
    color: #F4F7FC !important;
}

    .btn-new:hover {
        background-color: #57A9DC !important;
        color: #F4F7FC !important;
    }

.add-btn {
    height: calc(30.4px + 1rem);
}

.btn-global-check {
    background-color: transparent !important;
    color: #F4F7FC !important;
    font-size: 1.5rem !important;
}

    .btn-global-check:hover {
        color: #F4F7FC !important;
    }

.btn-remove.light {
    background-color: transparent !important;
    color: #92929D !important;
}

.btn-remove.dark {
    background-color: transparent !important;
    color: #92929D !important;
}

.btn-remove:hover {
    color: #C44046 !important;
}

.btn-no {
    background-color: #0079DD !important;
    color: #F4F7FC !important;
}

    .btn-no:hover {
        background-color: #C44046 !important;
        color: #F4F7FC !important;
    }

.btn-side-nav-show {
    background-color: #424242 !important;
    color: #92929D !important;
    margin: 9.2px 9.2px 9.2px auto;
}

    .btn-side-nav-show.closed {
        margin: 9.2px;
    }

    .btn-side-nav-show.light {
        background-color: #E1EFFC !important;
        color: #92929D !important;
    }

.table-dark {
    --bs-table-bg: transparent !important;
    --bs-table-hover-bg: #373b3e !important;
}

.table-light {
    --bs-table-bg: transparent !important;
    --bs-table-hover-bg: #F1F7FD !important;
    color: #131523 !important;
}

.thead-dark th {
    background-color: #161616 !important;
    z-index: 0 !important;
}

.thead-light th {
    background-color: #E1EFFC !important;
    z-index: 0 !important;
}

.thead-sort.light {
    color: #92929D !important;
}

    .thead-sort.light:hover {
        color: #0079DD !important;
    }

.modal-content.light {
    background-color: #FFF !important;
    color: #131523 !important;
}

.modal-content.dark {
    background-color: #2C2C2C !important;
    color: #92929D !important;
}

    .modal-content.dark.resource-card-preview-modal {
        background-color: #161616 !important;
    }

.modal-overlay {
    height: calc(100% + 72px);
    width: 100%;
    margin-top: -16px;
    margin-left: -15.5px;
    position: absolute;
    background-color: #000000;
    opacity: .25;
    z-index: 1;
}

.saving-button {
    --fa-animation-duration: 2s;
    --fa-fade-opacity: 0.2;
}

.close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
}

    .close:hover {
        color: #C44046 !important;
    }

.modal-header {
    border-bottom: none !important;
    align-items: flex-start !important;
}

.modal-footer {
    border-top: none !important;
}

.tab-content.dark .form-control {
    background-color: transparent !important;
    color: #F4F7FC !important;
    border: 1px solid #92929D;
}

    .tab-content.dark .form-control:focus {
        background-color: transparent !important;
        border-color: #0079DD !important;
        color: #F4F7FC !important;
    }

.tab-content.light .form-control {
    background-color: transparent !important;
    color: #161616 !important;
    border: 1px solid #92929D;
}

    .tab-content.light .form-control:focus {
        background-color: transparent !important;
        border-color: #0079DD !important;
        color: #161616 !important;
    }

.modal-content.dark .form-control {
    background-color: transparent !important;
    color: #F4F7FC !important;
    border: 1px solid #92929D;
}

    .modal-content.dark .form-control:focus {
        background-color: transparent !important;
        border-color: #0079DD !important;
        color: #F4F7FC !important;
    }

.modal-content.light .form-control {
    background-color: transparent !important;
    color: #161616 !important;
    border: 1px solid #92929D;
}

    .modal-content.light .form-control:focus {
        background-color: transparent !important;
        border-color: #0079DD !important;
        color: #161616 !important;
    }

.toggle-row {
    border-bottom: 15px solid transparent;
}

#toggle-group-header {
    margin-top: 40px;
    max-width: 85px;
    white-space: nowrap;
    text-align: left;
}

#toggle-group-spacer {
    margin-top: 20px;
    min-width: 85px;
}

#no-filter-dialog {
    margin-top: 90px;
    min-width: 140px;
    text-align: center;
}

option {
    background-color: #2C2C2C !important;
    color: #92929D !important;
}

.table-fix-head {
    overflow-y: auto;
    height: 90%;
}

    .table-fix-head thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .table-fix-head::-webkit-scrollbar {
        width: 8px;
    }

    .table-fix-head::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .table-fix-head::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .table-fix-head::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.table-header-icon-group {
    float: right;
}

.rs-calendar-time-dropdown-column > ul::-webkit-scrollbar {
    width: 8px;
}

.rs-calendar-time-dropdown-column > ul::-webkit-scrollbar-track {
    background-color: transparent;
}

.rs-calendar-time-dropdown-column > ul::-webkit-scrollbar-thumb {
    background-color: #92929D;
    border-radius: 20px;
    border: 3px solid #92929D;
}

    .rs-calendar-time-dropdown-column > ul::-webkit-scrollbar-thumb:hover {
        background-color: #0079DD;
        border-radius: 20px;
        border: 3px solid #0079DD;
    }

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: inherit !important;
    color: #0079DD !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell:hover {
    background-color: inherit !important;
}

.pagination-btn-container {
    width: fit-content !important;
}

.select-pagination-container {
    width: fit-content !important;
}

#pagination-select {
    display: inline-block;
    vertical-align: middle;
}

.entityCount {
    font-size: 12px;
}

.btn-outline-dark {
    background-color: #424242 !important;
}

    .btn-outline-dark:enabled {
        color: #F4F7FC !important;
    }

        .btn-outline-dark:enabled:hover {
            color: #F4F7FC !important;
            background-color: #161616 !important;
        }

.btn-outline-light {
    background-color: #BCDAF7 !important;
    color: #FFFFFF;
}

    .btn-outline-light:enabled {
        color: #0079DD !important;
    }

        .btn-outline-light:enabled:hover {
            color: #FFFFFF !important;
            background-color: #0079DD !important;
        }

.btn-edit-dashboard-settings {
    color: #000000 !important;
}

    .btn-edit-dashboard-settings.dark {
        color: #F4F7FC !important;
    }

.btn-edit-dashboard-save {
    color: #008000 !important;
}

.btn-edit-dashboard-cancel {
    color: #C44046 !important;
}

.btn-edit-dashboard {
    box-shadow: none;
    border-color: #92929D !important;
    margin: 1px;
}

    .btn-edit-dashboard.dark {
        box-shadow: none;
        background-color: #424242;
        border-color: #424242 !important;
    }

        .btn-edit-dashboard.dark:hover {
            color: #F4F7FC !important;
            background-color: #0079DD !important;
        }

    .btn-edit-dashboard.light {
        background-color: #FFF !important;
    }

        .btn-edit-dashboard.light:hover {
            color: #FFFFFF !important;
            background-color: #0079DD !important;
        }

.btn-widget-delete {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px !important;
    color: #C44046 !important;
}

.btn-resource-setting {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px !important;
    font-size: 18px !important;
}

.resource-carousel-group {
    position: absolute;
    bottom: 20px;
    left: 30px;
}

.resource-description {
    max-height: calc(100% - 60px);
}

    .resource-description::-webkit-scrollbar {
        width: 4px;
    }

    .resource-description::-webkit-scrollbar-track.rs-theme-dark {
        background-color: #2C2C2C;
    }

    .resource-description::-webkit-scrollbar-track.rs-theme-light {
        background-color: #E1EFFC;
    }

    .resource-description::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    .resource-description::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .resource-description::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.widget-container {
    position: relative;
    height: 100%;
}

.widgetValue {
    padding-top: 2em;
}

.widgetTitle {
    width: calc(70%);
}

.dashboard-editor-container {
    position: relative;
    z-index: 2;
}

.dashboard-editor-group {
    position: fixed;
    left: calc(50% - 95px);
    top: 30px;
    border: 1px solid #92929D;
    border-radius: 8px;
}

    .dashboard-editor-group.open {
        left: calc(50% - 95px);
    }

    .dashboard-editor-group.light {
        box-shadow: 2px 2px 5px #92929D;
        background-color: #F1F7FD;
    }

    .dashboard-editor-group.dark {
        box-shadow: 2px 2px 5px #000000;
    }

.admin-settings {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
}

    .admin-settings::-webkit-scrollbar {
        width: 8px;
    }

    .admin-settings::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .admin-settings::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .admin-settings::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.kb-article {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
}

    .kb-article::-webkit-scrollbar {
        width: 8px;
    }

    .kb-article::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .kb-article::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .kb-article::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.kb-select {
    display: inline-block;
    width: 350px;
    max-height: 100%;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    overflow-y: auto;
}

    .kb-select.dark {
        background-color: #161616;
    }

    .kb-select.light {
        background-color: #F1F7FD;
    }

    .kb-select::-webkit-scrollbar {
        width: 8px;
    }

    .kb-select::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .kb-select::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .kb-select::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.kb-content {
    font-family: 'Montserrat' !important;
}

    .kb-content.light {
        color: #333D47 !important;
    }

        .kb-content.light h1,
        .kb-content.light h2,
        .kb-content.light h3,
        .kb-content.light h4,
        .kb-content.light h5 {
            color: #9933CC !important;
        }

        .kb-content.light table td {
            min-width: 10px;
            padding: 6px 8px;
            border: 1px solid #333D47;
            overflow: auto;
            line-height: 1.2;
            word-break: break-word;
        }

        .kb-content.light img {
            box-shadow: 4px 4px 10px #92929D;
        }

    .kb-content.dark {
        color: #FFFFFF !important;
    }

        .kb-content.dark h1,
        .kb-content.dark h2,
        .kb-content.dark h3,
        .kb-content.dark h4,
        .kb-content.dark h5 {
            color: #57A9DC !important;
        }

        .kb-content.dark table td {
            min-width: 10px;
            padding: 6px 8px;
            border: 1px solid #FFFFFF;
            overflow: auto;
            line-height: 1.2;
            word-break: break-word;
        }

    .kb-content a:hover {
        color: #57A9DC !important;
    }

    .kb-content img.align-center {
        margin-left: auto;
        margin-right: auto;
    }


    .kb-content img.align-center {
        display: block;
    }

    .kb-content .align-center {
        text-align: center;
    }

    .kb-content img {
        max-width: 100%;
        height: auto;
    }

.kb-book-selection {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slideout {
    padding-bottom: 24px;
    display: inline-block;
    height: 100%;
}

#slideoutBlock {
    min-height: 55vh;
}

    #slideoutBlock.dark {
        background-color: #161616;
    }

    #slideoutBlock.light {
        background-color: #F1F7FD;
    }

.slideoutBarContainer {
    height: inherit;
}

.slideoutBar {
    height: inherit;
    min-width: 8px;
    cursor: pointer;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

    .slideoutBar.dark {
        background: rgba(66,66,66);
    }

    .slideoutBar.light {
        background: #E1EFFC;
    }

    .slideoutBar:hover ~ .slideoutIconContainer, .slideoutBar:hover ~ .slideoutIconContainerActive, .slideoutIconContainer:hover, .slideoutIconContainerActive:hover {
        color: #57A9DC !important;
    }

.slideoutIconContainer {
    height: 5vh;
    min-width: 22px;
    cursor: pointer;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: #92929D;
}

    .slideoutIconContainer.dark {
        background: rgba(66,66,66);
    }

    .slideoutIconContainer.light {
        background: #E1EFFC;
    }

.slideoutIconContainerActive {
    height: 5vh;
    min-height: 30px;
    max-height: 60px;
    min-width: 22px;
    cursor: pointer;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: #0079DD;
}

    .slideoutIconContainerActive.dark {
        background: rgba(66,66,66);
    }

    .slideoutIconContainerActive.light {
        background: #E1EFFC;
    }

a.slideoutLink {
    color: inherit !important;
    text-shadow: 0 0 10px #92929D;
}

body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-track.rs-theme-dark {
    background-color: #2C2C2C;
}

body::-webkit-scrollbar-track.rs-theme-light {
    background-color: #E1EFFC;
}

body::-webkit-scrollbar-corner {
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #92929D;
    border-radius: 20px;
    border: 3px solid #92929D;
}

    body::-webkit-scrollbar-thumb:hover {
        background-color: #0079DD;
        border-radius: 20px;
        border: 3px solid #0079DD;
    }

select::-webkit-scrollbar {
    width: 8px;
}

select::-webkit-scrollbar-track {
    background-color: transparent;
}

select::-webkit-scrollbar-thumb {
    background-color: #92929D;
    border-radius: 20px;
    border: 3px solid #92929D;
}

    select::-webkit-scrollbar-thumb:hover {
        background-color: #0079DD;
        border-radius: 20px;
        border: 3px solid #0079DD;
    }

.react-select__menu-list::-webkit-scrollbar {
    width: 8px;
}

.react-select__menu-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
    background-color: #92929D;
    border-radius: 20px;
    border: 3px solid #92929D;
}

    .react-select__menu-list::-webkit-scrollbar-thumb:hover {
        background-color: #0079DD;
        border-radius: 20px;
        border: 3px solid #0079DD;
    }

#relativity-select-stats {
    width: 33%;
}

#toggle-cell {
    width: 66px;
}

.tri-state-toggle {
    position: relative;
    -webkit-appearance: none;
    width: 2.8rem;
    height: 1.1rem;
    padding: 0.15rem;
    margin-left: 0.7rem;
    margin-top: 0.2rem;
    border-radius: 1rem;
    background: #92929D;
    outline: none;
    opacity: 0.7;
    -webkit-transition: opacity .2s;
    cursor: pointer;
}

    .tri-state-toggle::-webkit-slider-thumb {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 0.8rem;
        background: #161616;
    }

    .tri-state-toggle:hover {
        opacity: 1;
    }

.toggle-position {
    position: relative;
    display: inline-block;
    top: -0.45rem;
    height: 0.2rem;
    width: 0.2rem;
    border-radius: 0.2rem;
    pointer-events: none;
    background: #161616;
    opacity: 0.4;
}

.toggle-first-position {
    left: -2.3rem;
}

.toggle-second-position {
    left: -1.7rem;
}

.toggle-third-position {
    left: -1.1rem;
}

.info-spacer {
    font-size: 15px;
}

.nav-tabs {
    border-bottom: 1px solid #92929D;
}

    .nav-tabs .nav-link .remove {
        position: relative;
        bottom: 8px;
        left: 10px;
        font-size: 15px !important;
        color: #92929D !important;
        opacity: 0.75;
    }

    .nav-tabs.dark .nav-link:hover .remove:hover,
    .nav-tabs.light .nav-link:hover .remove:hover {
        color: #C44046 !important;
    }

    .nav-tabs.dark .nav-link:hover .remove,
    .nav-tabs.dark .nav-link.active .remove {
        color: #2c2c2c !important;
    }

    .nav-tabs .nav-link .add {
        position: relative;
        bottom: 8px;
        left: 10px;
        font-size: 15px !important;
    }

    .nav-tabs.dark .nav-link {
        color: #92929D !important;
        background-color: #161616 !important;
        border-color: #92929D !important;
        margin-right: 1px;
    }

        .nav-tabs.dark .nav-link:hover {
            color: #161616 !important;
            background-color: #92929D !important;
        }

        .nav-tabs.dark .nav-link.active {
            color: #161616 !important;
            background-color: #92929D !important;
        }

            .nav-tabs.dark .nav-link.active:hover {
                color: #161616 !important;
                background-color: #92929D !important;
            }

    .nav-tabs.light .nav-link {
        color: #131523 !important;
        background-color: #FFFFFF !important;
        border-color: #92929D !important;
        margin-right: 1px;
    }

        .nav-tabs.light .nav-link:hover {
            color: #0079DD !important;
            background-color: #F2F8FE !important;
        }

        .nav-tabs.light .nav-link.active {
            color: #0079DD !important;
            background-color: #F2F8FE !important;
        }

            .nav-tabs.light .nav-link.active:hover {
                color: #0079DD !important;
                background-color: #F2F8FE !important;
            }

.nav-link {
    font-size: 16px !important;
}

#navMaintenanceNotifier {
    color: #C44046;
}

.scroll {
    overflow-y: auto;
    overflow-x: hidden;
}

    .scroll::-webkit-scrollbar {
        width: 5px;
    }

    .scroll::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .scroll::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .scroll::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.link-headers {
    width: inherit;
    border-bottom: 1px solid #DEE2E6;
    margin-bottom: 5px !important;
}

.link-header {
    font-weight: bold;
}

.scrollable-additional-details-content {
    overflow-y: auto;
    height: 150px;
    width: inherit;
}

.scrollable-tab-content {
    overflow-y: auto;
    height: 100%;
    width: inherit;
}

.link-tab-content {
    overflow-y: auto;
    height: 64.8%;
    width: inherit;
}

    .link-tab-content::-webkit-scrollbar,
    .scrollable-tab-content::-webkit-scrollbar,
    .scrollable-additional-details-content::-webkit-scrollbar {
        width: 5px;
    }

    .link-tab-content::-webkit-scrollbar-track,
    .scrollable-tab-content::-webkit-scrollbar-track,
    .scrollable-additional-details-content::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .link-tab-content::-webkit-scrollbar-thumb,
    .scrollable-tab-content::-webkit-scrollbar-thumb,
    .scrollable-additional-details-content::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .link-tab-content::-webkit-scrollbar-thumb:hover,
        .scrollable-tab-content::-webkit-scrollbar-thumb:hover,
        .scrollable-additional-details-content::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

/*This style is necessary for our modal height and scroll to work properly. Do not remove*/
.tab-pane {
    height: 282px;
}

.btn-short {
    line-height: 1 !important;
}

.right-align {
    text-align: right;
}

.active-toggle-label {
    color: #0079DD !important;
}

.toggle-label {
    color: #92929D !important;
}

.modal-tip {
    z-index: 2000 !important;
}

#subscription-warning {
    color: #f1c40f !important;
    cursor: pointer;
    opacity: 1 !important;
}

.tiny-msg {
    font-size: 8pt;
    font-style: italic;
}

.clickable .permissionIcon {
    font-size: 24pt !important;
}

.deactivatedPermission {
    color: #C44046 !important;
}

.activatedPermission {
    color: #57A9DC !important;
}

.active-role.dark {
    color: #F4F7FC !important;
}

.inactive-role.dark {
    color: #424242 !important;
}

.active-role.light {
    color: #424242 !important;
}

.inactive-role.light {
    color: #D3D6DB !important;
}

.power-btn-on {
    color: #57A9DC !important;
}

    .power-btn-on:hover {
        color: #C44046 !important;
    }

.power-btn-off {
    color: #92929D !important;
}

    .power-btn-off:hover {
        color: #57A9DC !important;
    }

.restart-btn:hover {
    color: #0079DD !important;
}

.loading-icon {
    position: relative;
    top: 5px;
    left: 5px;
}

.stat-card-title {
    font-weight: 700 !important;
}

.card-nav-enabled:hover {
    color: #0079DD !important;
}

.card-nav-disabled {
    color: #92929D !important;
}

.bottom-border {
    border-bottom: 1px solid !important;
}

.blog-thumb {
    margin-left: auto;
}

.top-border {
    border-top: 1px solid !important;
}

.resource-image {
    width: 150px;
    height: 150px;
}

    .resource-image.light {
        box-shadow: 4px 4px 10px #92929D;
    }

a .resource-card-title.dark {
    color: #FFFFFF !important;
}

a .resource-card-title.light {
    color: #000000 !important;
}

a .resource-card-title:hover {
    color: #0079DD !important;
}

.thumbnail-preview {
    width: 24px;
    height: 24px;
}

.nav-logo {
    height: 65px;
}

a.selected {
    color: #0079DD !important;
}

.search-result {
    width: 95% !important;
}

.search-results {
    height: 90%;
    width: 100%;
    overflow-y: auto;
}

    .search-results::-webkit-scrollbar {
        width: 8px;
    }

    .search-results::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .search-results::-webkit-scrollbar-thumb {
        background-color: #92929D;
        border-radius: 20px;
        border: 3px solid #92929D;
    }

        .search-results::-webkit-scrollbar-thumb:hover {
            background-color: #0079DD;
            border-radius: 20px;
            border: 3px solid #0079DD;
        }

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-label.disabled {
    opacity: .50;
}

.graph-widget-logo {
    height: 1.5em;
    width: 1.5em;
}

.fa-layers {
    cursor: pointer;
}

.fa-layers-counter {
    font-size: 2em;
}

.alert.light {
    color: #92929D !important;
}

.alert.dark {
    color: #ffffff !important;
}

.notification-dropdown-menu {
    width: 300px !important;
}

.side-nav-arrow {
    float: right;
    line-height: 47px;
}

.mass-edit-icon-1 {
    width: 55px !important;
}

.mass-edit-icon-2 {
    width: 65px !important;
}

.mass-edit-icon-3 {
    width: 75px !important;
}

.mass-edit-icon-4 {
    width: 85px !important;
}

.collapsible-header.dark {
    background-color: #161616 !important;
}

.collapsible-header.light {
    background-color: #F1F7FD !important;
}

/*  media ranges  */
@media (max-width: 10000px) {
    .navbar-collapse {
        display: flex !important;
        flex-basis: auto !important;
        flex-direction: row !important;
        min-width: 572px !important;
        max-height: 40px;
    }

    .navbar {
        flex-wrap: nowrap !important;
    }

    .navbar-nav {
        flex-direction: row;
    }
}

@media screen and (min-width: 2200px) {
    body, .btn.media-controlled {
        font-size: 14px !important;
    }

    .static-font-size {
        font-size: 16px !important;
    }

    h2.media-controlled {
        font-size: 2.5rem !important;
    }

    .search-input.media-controlled {
        height: 38px;
        width: 300px;
        padding-left: 40px;
    }

    .searchIcon {
        left: 32px;
        top: 1px;
    }

    #view-all.media-controlled {
        padding-top: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .slideoutIconContainerActive.media-controlled, .slideoutIconContainer.media-controlled {
        min-width: 28px;
    }

    .slideoutBar.media-controlled {
        min-width: 8px;
    }

    .form-check.media-controlled {
        min-height: 1.8rem;
    }
}

@media screen and (max-width: 1850px) {
    .adminNavItem.media-controlled {
        margin-right: 1rem !important;
    }
}

@media screen and (max-width: 1660px) {
    body, .btn.media-controlled {
        font-size: 10px !important;
    }

    .static-font-size {
        font-size: 16px !important;
    }

    .list-header.media-controlled {
        font-size: 1.75rem !important;
    }

    .admin-header.media-controlled {
        padding-left: 0rem !important;
    }

    #toggle-group-header.media-controlled {
        font-size: 0.75rem !important;
    }

    .search-input.media-controlled {
        height: 27px;
    }

    .column-search-input.media-controlled, .column-search-spacer.media-controlled {
        height: 20px;
        padding-left: 10px;
    }

    .searchIcon.media-controlled {
        left: 21px;
        top: 0px;
    }

    .btn.btn-new.media-controlled {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    #view-all.media-controlled {
        padding-top: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .table-fix-head.media-controlled, .pagination-controls.media-controlled {
        padding-left: 0.5rem !important;
    }

    .slideoutIconContainerActive.media-controlled, .slideoutIconContainer.media-controlled {
        min-width: 16px;
    }

    .slideoutBar.media-controlled {
        min-width: 4px;
    }

    .filter-block.media-controlled {
        width: 275px !important;
    }

    a.column-filter-icon.media-controlled {
        padding: 0.5rem;
        margin-left: 0.25rem;
        border-radius: 25px;
        font-size: 0.6rem;
    }

    .admin-nav.media-controlled {
        margin-left: 1rem !important;
    }

    .form-check.media-controlled {
        min-height: 1.25rem;
    }
}

@media screen and (max-width: 1500px) {
    .adminNavItem.media-controlled {
        margin-right: auto !important;
    }
}

/* minimum screen size */

@media screen and (max-width: 1360px) {
    body {
        width: 1360px;
        overflow: auto;
    }

    .main-page {
        min-width: 1360px !important;
        width: auto !important;
        width: 1360px !important;
    }

    .page-content {
        min-width: 1300px !important;
        width: auto !important;
        width: 1300px !important;
    }

        .page-content.open {
            position: absolute;
            left: 250px;
            height: calc(100vh - 91px);
            min-height: 629px;
        }

    .top-nav-body.dark {
        z-index: 2;
    }
}

@media screen and (max-height: 720px) {
    body {
        height: 720px;
        overflow: auto;
    }
}
